import React, { PureComponent } from 'react'
import ApiManager from '../../api/ApiManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './PlacesPage.module.scss'
import PropTypes from 'prop-types'
import layout from '../../DemoSingeLayout.module.scss'

class PlacesPage extends PureComponent {
  static propTypes = {
    uploadText: PropTypes.string,
  }

  static defaultProps = {
    uploadText: 'Для загрузки, перетащите файл сюда.',
  }

  value = ''
  imgURL = ''
  labelText = []
  apiHost = 'places?'
  rectangles = []

  addLoader = () => {
    if (this.loader) {
      this.loader.classList.add(layout.loaderActive)
      this.loader.scrollIntoView({ behavior: 'smooth' })
    }
  }

  removeLoader = () => {
    if (this.loader) this.loader.classList.remove(layout.loaderActive)
  }

  chooseImage = el => {
    if (el.target.files.length > 0) {
      this.imgURL = window.URL.createObjectURL(el.target.files[0])
    }
    this.forceUpdate()
  }

  uploadImage = imageData => {
    const imageUrl = window.URL.createObjectURL(imageData)
    this.setFullImage(imageUrl)
    this.addLoader()
    ApiManager.sendRequest(
      this.apiHost,
      ApiManager.parsePlaces,
      message => {
        this.removeLoader()
        this.placesDescription(message)
      },
      undefined,
      imageData
    )
  }

  setFullImage(source) {
    this.imgURL = source
    this.forceUpdate()
  }

  ondragover = () => {
    this.hover = true
    return false
  }

  placesDescription = message => {
    this.labelText = message
    this.forceUpdate()
  }

  uploadUrl = imageUrl => {
    this.setFullImage(imageUrl)
    this.addLoader()
    ApiManager.sendRequest(
      this.apiHost,
      ApiManager.parsePlaces,
      message => {
        this.removeLoader()
        this.placesDescription(message)
      },
      { picUrl: imageUrl }
    )
  }

  onDrop = event => {
    this.hover = false
    event.preventDefault()
    if (event.dataTransfer.files.length === 0) {
      const url = event.dataTransfer.getData('text/uri-list')
      this.uploadUrl(url)
    } else {
      const file = event.dataTransfer.files[0]
      this.uploadImage(file)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={layout.loader}
          ref={loader => {
            this.loader = loader
          }}>
          <i></i>
          <span>Идёт распознавание...</span>
        </div>
        <div
          onDragOver={e => {
            this.hover = true
            e.preventDefault()
            return false
          }}
          onDragLeave={() => {
            this.hover = false
            return false
          }}
          onDrop={this.onDrop}
        >
          <input
            style={{ display: 'none' }}
            type="file"
            ref={input => {
              this.aInput = input
            }}
            onChange={el => {
              if (el.target.files.length > 0) {
                this.uploadImage(el.target.files[0])
              }
              this.forceUpdate()
            }}
          />
          <div style={{ display: this.imgURL === '' ? 'none' : 'flex' }} className={style.tagContainer}>
            {this.labelText.map((item, i) => (
              <div className={style.tag} key={`label-value-${i}`}>
                <h4 dangerouslySetInnerHTML={{ __html: item }} />
              </div>
            ))}
          </div>
          <div
            onClick={() => {
              this.aInput.click()
            }}
            className={style.uploadContainer}
          >
            <div style={{ display: this.imgURL === '' ? 'none' : 'block' }}>
              <img
                alt=""
                ref={img => {
                  this.fullImage = img
                }}
                src={this.imgURL}
                style={{
                  display: 'block',
                  position: 'relative',
                  width: '100%',
                }}
              />
            </div>
            <div className={style.uploadBg} style={{ display: this.imgURL !== '' ? 'none' : 'flex' }}>
              <FontAwesomeIcon size="10x" icon={['fas', 'cloud-upload-alt']} />
              <p dangerouslySetInnerHTML={{ __html: this.props.uploadText }} />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PlacesPage
